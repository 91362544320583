import PropTypes from "prop-types";

const Account = {
  name: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  __v: PropTypes.number.isRequired,
  containers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};

export default Account;
