import React, { Component } from "react";
import PropTypes from "prop-types";
import Ssomg from "ssomg-react";
import { Link } from "react-router-dom";
import { Form, Field } from "roar-component-library";
import Sidebar from "../components/sidebar/Sidebar.tsx";
import Cookies from "./Cookies";

export default class Container extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      loading: true,
      container: {},
      account: {},
      cookies: [],
      error: false,
      mode: false,
    };
    this.setContainer = this.setContainer.bind( this );
    this.updateContainer = this.updateContainer.bind( this );
    this.destroyContainer = this.destroyContainer.bind( this );
    this.publishContainer = this.publishContainer.bind( this );
    this.setCookiesState = this.setCookiesState.bind( this );
    this.getVersions = this.getVersions.bind( this );
  }

  componentDidMount() {
    this.setContainer();
  }

  setContainer() {
    const { match, Server } = this.props;
    const { accountId, containerId } = match.params;
    this.setState( { loading: true } );
    this.setAccount();
    Server.get( `/api/accounts/${ accountId }/containers/${ containerId }`,
      { ga: { label: "/api/accounts/:accountId/containers/:containerId" } } )
      .then( ( res ) => {
        const container = res.data.data;
        if ( container.popupVersion ) {
          container.popupVersion = [ { label: container.popupVersion, value: container.popupVersion } ];
        } else {
          container.popupVersion = [];
        }
        this.setState( {
          container, cookies: container.cookies, loading: false, error: false,
        } );
        document.title = `${ res.data.data.name } - Popups - Cookie Manager`;
      } )
      .catch( ( err ) => {
        this.setState( {
          error: err.message || err || "An error occurred. Please wait a moment and try again.",
          loading: false,
        } );
      } );
  }

  setAccount() {
    const { match, Server } = this.props;
    const { accountId } = match.params;
    Server.get( `/api/accounts/${ accountId }`, { ga: { label: "/api/accounts/:accountId" } } )
      .then( ( res ) => {
        this.setState( {
          account: res.data.data,
        } );
      } ).catch( ( err ) => {
        console.error( err );
        this.setState( {
          account: {},
        } );
      } );
  }

  setCookiesState( cookies ) {
    this.setState( { cookies } );
  }

  getVersions( val ) {
    const { Server } = this.props;
    return Server.get( "/api/containerarchives" )
      .then( ( res ) => res.data.data
        .filter( ( version ) => version.indexOf( val ) >= 0 )
        .map( ( version ) => ( { label: `${ version }`, value: version } ) ) )
      .then( ( versions ) => {
        if ( versions.length > 0 ) return versions;
        throw new Error( "No matching user" );
      } );
  }

  checkIfAdmin() {
    const { account } = this.state;
    if ( !account ) return false;
    const { user } = this.props;
    if ( user.roles.indexOf( "superuser" ) >= 0 ) return true;
    if ( !account.users || !user ) return false;

    for ( let i = 0; i < account.users.length; i += 1 ) {
      if ( account.users[ i ].userId === user._id ) {
        if ( account.users[ i ].role === "admin" ) {
          return true;
        }
      }
    }
    return false;
  }

  updateContainer( update ) {
    const { match, Server } = this.props;
    const { cookies } = this.state;
    const { accountId, containerId } = match.params;
    const {
      name, title, introduction, customStyles, popupVersion, closedPopupText, cookiePolicyUrl,
    } = update;
    const body = {
      name, title, introduction, customStyles, cookies, closedPopupText, cookiePolicyUrl,
    };
    if ( popupVersion && popupVersion.length > 0 ) {
      body.popupVersion = popupVersion[ 0 ].value;
    }

    return Server.post( `/api/accounts/${ accountId }/containers/${ containerId }`, body,
      { ga: { label: "/api/accounts/:accountId/containers/:containerId" } } )
      .then( ( res ) => {
        const container = res.data.data;
        if ( container.popupVersion ) {
          container.popupVersion = [ { label: container.popupVersion, value: container.popupVersion } ];
        } else {
          container.popupVersion = [];
        }
        this.setState( { container, cookies: container.cookies, mode: false } );
        document.getElementById( "previewIframe" ).src += "";
      } );
  }

  destroyContainer() {
    const { match, Server, history } = this.props;
    const { accountId, containerId } = match.params;
    if ( !window.confirm( "Are you sure you want to delete this popup? This cannot be undone." ) ) {
      return false;
    }
    return Server.post( `/api/accounts/${ accountId }/containers/${ containerId }/destroy`,
      { ga: { label: "/api/accounts/:accountId/containers/:containerId/destroy" } } )
      .then( ( ) => {
        history.push( `/account/${ accountId }` );
      } );
  }

  publishContainer() {
    const { match, Server } = this.props;
    const { accountId, containerId } = match.params;
    if ( !window.confirm( "Are you sure you want to publish this popup?" ) ) {
      return false;
    }
    return Server.post( `/api/accounts/${ accountId }/containers/${ containerId }/publish`,
      { ga: { label: "/api/accounts/:accountId/containers/:containerId/publish" } } )
      .then( ( res ) => {
        this.setState( { container: res.data.data, cookies: res.data.data.cookies, mode: false } );
        document.getElementById( "previewIframe" ).src += "";
      } );
  }

  headerMarkup() {
    const { container } = this.state;
    const { match } = this.props;
    const { accountId, containerId } = match.params;
    return (
      <>
        <h1>
          { container ? container.name : "" }
          <button
            type="button"
            onClick={this.setContainer}
            aria-label="Refresh popup"
            title="Refresh popup"
            className="account__edit"
          >
            <i className="fas fa-sync-alt" />
          </button>
          <button
            type="button"
            onClick={() => { this.setState( { mode: "edit" } ); }}
            aria-label="Edit popup"
            title="Edit container"
            className="account__edit"
          >
            <i className="fas fa-edit" />
          </button>
          <Link
            to={`/account/${ accountId }`}
            title="Back to account"
            aria-label="Back to account"
            className="account__edit"
          >
            <i className="fas fa-arrow-left" />
          </Link>
        </h1>
        <h4>
          { container && container.version > 1 ? `Currently editing version ${ container.version }` : "" }
          {" "}
          <Link
            to={`/account/${ accountId }/${ containerId }/history`}
            className="tertiary account__edit"
          >
            Version history
          </Link>
        </h4>
      </>
    );
  }

  render() {
    const {
      loading, container, error, mode, cookies,
    } = this.state;
    const { match } = this.props;
    const { accountId, containerId } = match.params;
    if ( loading ) {
      return (
        <div>
          <div className="text-center"><span className="spinner spinner--blue" /></div>
        </div>
      );
    }
    if ( error ) {
      return (
        <div>
          {this.headerMarkup()}
          <h2 className="text-center">{error}</h2>
        </div>
      );
    }
    let lastPubDate;
    if ( container.lastPublishedOn ) {
      const d = new Date( container.lastPublishedOn );
      lastPubDate = d.toLocaleString();
    }
    const isAdmin = this.checkIfAdmin();
    return (
      <div>
        {this.headerMarkup()}
        <div>
          { container.lastPublishedOn
            ? `Published version ${ container.version - 1 } at: ${ lastPubDate }`
            : (
              "Not published"
            )}
          <strong />
          { container.resourceUrl ? (
            <details>
              <summary>Embed Code:</summary>
                Add the following code snippet to your website&apos;s HTML `head` tag as high as possible:
              <code>
                {`<script>!function(t,e,r,a){var c=t.createElement(e);c.type="text/javascript",c.async=!0, c.src="${ "https://roardigital.fra1.cdn.digitaloceanspaces.com/rd-cx/rcc-" }"+a+".js" ;var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(c,n)}( document,"script",0,"${ container._id }");</script>`}
              </code>
            </details>
          ) : false}
        </div>
        <h2>Popup preview:</h2>
        <Form
          onFormSubmit={this.publishContainer}
          successMessage="Published"
          actionName="Publish"
          actionClass="primary"
        />
        <iframe
          className="previewIframe"
          id="previewIframe"
          title="Previewer"
          name="preview_iframe"
          src={`${ Ssomg.env.get( "REACT_APP_NODE_HOST" ) }/resources/accounts/${ accountId }/containers/${ containerId }`}
        />
        <details>
          <summary>View Popup Config as JSON</summary>
          <code>{JSON.stringify( container, null, 2 )}</code>
        </details>
        <Sidebar open={mode === "edit"} back={() => { this.setState( { mode: false } ); }} style={{ width: "500px" }}>
          <Form onFormSubmit={this.updateContainer} actionName="Save changes" actionClass="primary">
            <h2>
              Edit Popup
              <button
                type="button"
                onClick={() => { this.setState( { mode: false } ); }}
                aria-label="Back"
                title="Back"
                className="account__edit"
              >
                <i className="fas fa-arrow-left" />
              </button>
            </h2>
            <Field
              name="name"
              type="text"
              parentClass="block"
              default={container.name}
              label="Popup name"
              minLen={1}
              maxLen={50}
              trim
            />
            <Field
              name="title"
              type="text"
              parentClass="block"
              default={container.title}
              label="Popup title"
            />
            <Field
              name="introduction"
              type="textarea"
              rows={10}
              parentClass="block"
              default={container.introduction}
              label="Popup introduction"
            />
            <Field
              name="closedPopupText"
              type="text"
              parentClass="block"
              default={container.closedPopupText}
              label="Open Popup Button Text"
            />
            <Field
              name="cookiePolicyUrl"
              type="text"
              parentClass="block"
              default={container.cookiePolicyUrl}
              label="Url for Cookie Policy page"
            />
            <Field
              name="customStyles"
              type="code"
              codeOptions={{ mode: "css", lineNumbers: true }}
              rows={10}
              parentClass="block"
              default={container.customStyles}
              label="Custom Styles"
            />
            <Field
              name="popupVersion"
              type="autocomplete"
              autocomplete={( text ) => this.getVersions( text )}
              maxLen={1}
              parentClass="block"
              default={container.popupVersion}
              label="Popup Code Version"
            />
          </Form>
          <Form
            onFormSubmit={() => this.setState( { mode: "cookies" } )}
            actionName="Edit Cookies"
            actionClass="secondary"
          />
          { isAdmin ? (
            <Form
              onFormSubmit={this.destroyContainer}
              actionName="Delete"
              actionClass="secondary fl-r"
            />
          ) : false}
        </Sidebar>
        <Cookies
          editCookiesMode={mode === "cookies"}
          back={() => { this.setState( { mode: "edit" } ); }}
          cookies={cookies}
          setCookies={this.setCookiesState}
        />
      </div>
    );
  }
}

Container.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  Server: PropTypes.instanceOf( Ssomg.Network ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

Container.defaultProps = {
  user: {},
};
