/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Ssomg from "ssomg-react";

import HeaderPlaceholder from "./views/AccountHeaderView";
import AdminView from "./views/AccountAdminView";
import UserView from "./views/AccountUserView";
import AccountEditSidebar from "./views/AccountEditSidebar";

class Account extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      account: {
        data: {},
        loading: true,
        error: null,
      },
      editMode: false,
    };
    this.upsertUsers = this.upsertUsers.bind( this );
    this.setAccount = this.setAccount.bind( this );
    this.update = this.update.bind( this );
    this.delete = this.delete.bind( this );
    this.autocomplete = this.autocomplete.bind( this );
    this.createContainer = this.createContainer.bind( this );
    this.toggleEditMode = this.toggleEditMode.bind( this );
  }

  componentDidMount() {
    this.setAccount();
  }

  setAccount( addSpinner = true ) {
    const { initialAccount, Server } = this.props;
    const { account } = this.state;
    if ( addSpinner ) {
      account.loading = true;
      account.error = null;
      this.setState( { account } );
    }
    Server.get( `/api/accounts/${ initialAccount._id }`, { ga: { label: "/api/accounts/:accountId" } } )
      .then( ( res ) => {
        this.setState( {
          account: {
            data: res.data.data,
            loading: false,
          },
        } );
      } ).catch( ( err ) => {
        console.error( err );
        this.setState( {
          account: {
            data: {},
            loading: false,
            error: err.message || err || "An error occurred. Please wait a moment and try again.",
          },
        } );
      } );
  }

  upsertUsers() {
    const { Server } = this.props;
    return Server.post( "/api/users/upsert", {} ); // upsert user data in the background
  }

  toggleEditMode( force ) {
    if ( typeof force === "undefined" ) {
      this.setState( ( prevState ) => ( {
        editMode: !prevState.editMode,
      } ) );
    } else {
      this.setState( { editMode: force } );
    }
  }

  checkIfAdmin() {
    const { account } = this.state;
    const { data } = account;
    const { user } = this.props;
    if ( !user ) return false;
    if ( user.roles.indexOf( "superuser" ) >= 0 ) return true;
    for ( let i = 0; i < data.users.length; i += 1 ) {
      if ( data.users[ i ].userId === user._id ) {
        if ( data.users[ i ].role === "admin" ) {
          return true;
        }
      }
    }
    return false;
  }

  createContainer( body ) {
    const { Server, history } = this.props;
    const { account } = this.state;
    const { data } = account;
    return Server.post( `/api/accounts/${ data._id }/containers`, body,
      { ga: { label: "/api/accounts/:accountId/containers" } } ).then( ( res ) => {
      const container = res.data.data;
      history.push( `/account/${ data._id }/${ container._id }` );
    } );
  }

  delete() {
    const {
      Server, updateNav, history,
    } = this.props;
    const { account } = this.state;
    const { data } = account;
    if ( !window.confirm( "Account deletion cannot be undone. Are you sure you wish to proceed?" ) ) return;
    return Server.post( `/api/accounts/${ data._id }/destroy`, {},
      { ga: { label: "/api/accounts/:accountId/destroy" } } )
      .then( ( ) => {
        updateNav();
        history.push( "/" );
      } );
  }

  update( body ) {
    const {
      Server, updateNav, history,
    } = this.props;
    const { account } = this.state;
    const { data } = account;
    return Server.post( `/api/accounts/${ data._id }`, body, { ga: { label: "/api/accounts/:accountId" } } )
      .then( ( res ) => {
        updateNav();
        this.setState( {
          account: {
            data: res.data.data,
            loading: false,
          },
          editMode: false,
        } );
        history.push( `/account/${ data._id }` );
      } );
  }

  autocomplete( val ) {
    const { Server } = this.props;
    return Server.get( `/api/users/?search=${ val }`, { ga: { label: "/api/users/?search" } } )
      .then( ( res ) => res.data.users
        .map( ( user ) => ( { label: `${ user.name }`, value: user.userId, _id: user.userId } ) ) )
      .then( ( users ) => {
        if ( users.length > 0 ) return users;
        throw new Error( "No matching user" );
      } );
  }

  render() {
    const { account, editMode } = this.state;
    const { data, loading, error } = account;
    const { initialAccount } = this.props;
    if ( !loading && !error && data._id !== initialAccount._id ) {
      this.setAccount();
    }
    if ( loading || error ) {
      return (
        <HeaderPlaceholder
          loading={loading}
          error={error}
          initialAccount={initialAccount}
          setAccount={this.setAccount}
        />
      );
    }
    const isAdmin = this.checkIfAdmin();
    if ( isAdmin ) {
      return (
        <>
          <AdminView
            data={data}
            createContainer={this.createContainer}
            toggleEditMode={this.toggleEditMode}
            setAccount={this.setAccount}
          />
          <AccountEditSidebar
            data={data}
            autocomplete={this.autocomplete}
            editMode={editMode}
            toggleEditMode={this.toggleEditMode}
            setAccount={this.setAccount}
            update={this.update}
            upsertUsers={this.upsertUsers}
            deleteAccount={this.delete}
          />
        </>
      );
    }
    return <UserView data={data} setAccount={this.setAccount} />;
  }
}
Account.propTypes = {
  initialAccount: PropTypes.object.isRequired,
  user: PropTypes.object,
  Server: PropTypes.instanceOf( Ssomg.Network ).isRequired,
  history: PropTypes.object.isRequired,
  updateNav: PropTypes.func.isRequired,
};

Account.defaultProps = {
  user: {},
};

export default Account;
