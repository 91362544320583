import React from "react";
import PropTypes from "prop-types";
import InitialAccountSpec from "../helpers/InitialAccountSpec";

const AccountHeaderView = ( props ) => {
  const {
    initialAccount, loading, error, setAccount,
  } = props;
  if ( loading ) {
    return (
      <div>
        <h1>
          { initialAccount.name }
        </h1>
        <div className="text-center"><span className="spinner spinner--blue" /></div>
      </div>
    );
  }
  if ( error ) {
    return (
      <div>
        <h1>
          { initialAccount.name }
          <button type="button" onClick={setAccount} aria-label="Refresh account" title="Refresh account" className="account__edit">
            <i className="fas fa-sync-alt" />
          </button>
        </h1>
        <h2 className="text-center">{error}</h2>
      </div>
    );
  }
};

AccountHeaderView.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  initialAccount: PropTypes.shape( InitialAccountSpec ).isRequired,
  setAccount: PropTypes.func.isRequired,
};

export default AccountHeaderView;
