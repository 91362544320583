/* eslint-disable camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import Ssomg from "ssomg-react";
import { Form, Field } from "roar-component-library";

class NewAccount extends Component {
  constructor( props ) {
    super( props );
    this.state = { redirect: false };
  }

  onFormSubmit = ( data ) => {
    const {
      Server,
      updateNav,
    } = this.props;
    return Server.post( "/api/accounts", data ).then( ( created ) => {
      if ( created.data.data._id ) {
        updateNav().then( () => {
          this.setState( {
            redirect: `/account/${ created.data.data._id }`,
          } );
        } );
      }
    } );
  }

  render() {
    const {
      redirect,
    } = this.state;

    if ( redirect ) {
      return ( <Redirect to={redirect} /> );
    }
    return (
      <div className="section">
        <h1 className="text__title">New Cookie Account</h1>
        <Form onFormSubmit={this.onFormSubmit} actionClass="primary" actionName="Create">
          <Field type="text" label="Name" name="name" trim maxLen={50} minLen={1} />
        </Form>
      </div>
    );
  }
}

NewAccount.propTypes = {
  Server: PropTypes.instanceOf( Ssomg.Network ).isRequired,
  updateNav: PropTypes.func.isRequired,
};

export default NewAccount;
