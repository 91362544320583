import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "roar-component-library";
import Sidebar from "../../../components/sidebar/Sidebar.tsx";

import AccountSpec from "../helpers/AccountSpec";

const AccountEditSidebar = ( props ) => {
  const {
    data, editMode, autocomplete, toggleEditMode, update, upsertUsers, setAccount, deleteAccount,
  } = props;
  return (
    <Sidebar open={editMode} back={() => { toggleEditMode( false ); }} style={{ width: "350px" }}>
      <h2>
          Edit Account
        <button type="button" onClick={() => { toggleEditMode( false ); }} aria-label="Back" title="Back" className="account__edit">
          <i className="fas fa-arrow-left" />
        </button>
      </h2>
      <Form onFormSubmit={update} actionName="Update" actionClass="primary">
        <Field label="Name" type="text" name="name" maxLen={50} minLen={1} trim default={data.name} parentClass="block" />
        <Field
          label="Admins"
          parentClass="block"
          covalidate={[ "editors" ]}
          validate={( val, form ) => {
            if ( val.length === 0 ) {
              return "Must have at least 1 admin";
            }
            const errors = [];
            for ( let i = 0; i < form.editors.length; i += 1 ) {
              for ( let j = 0; j < val.length; j += 1 ) {
                if ( val[ j ].value === form.editors[ i ].value ) {
                  errors.push( `${ val[ j ].label } cannot be an admin and an editor` );
                }
              }
            }
            return errors;
          }}
          type="autocomplete"
          autocomplete={autocomplete}
          name="admins"
          default={data.users.filter( ( roleMap ) => roleMap.role === "admin" ).map( ( roleMap ) => {
            if ( roleMap.user ) {
              return { value: roleMap.user.userId, label: roleMap.user.name, _id: roleMap.user.userId };
            }
            return { value: "", label: "User deleted" };
          } )}
        />
        <Field
          label="Editors"
          covalidate={[ "admins" ]}
          parentClass="block"
          validate={( val, form ) => {
            const errors = [];
            for ( let i = 0; i < form.admins.length; i += 1 ) {
              for ( let j = 0; j < val.length; j += 1 ) {
                if ( val[ j ].value === form.admins[ i ].value ) {
                  errors.push( `${ val[ j ].label } cannot be an admin and an editor` );
                }
              }
            }
            return errors;
          }}
          type="autocomplete"
          autocomplete={autocomplete}
          name="editors"
          default={data.users.filter( ( roleMap ) => roleMap.role === "edit" ).map( ( roleMap ) => {
            if ( roleMap.user ) {
              return { value: roleMap.user.userId, label: roleMap.user.name, _id: roleMap.user.userId };
            }
            return { value: "", label: "User deleted" };
          } )}
        />
      </Form>
      <Form onFormSubmit={upsertUsers} actionName="Refresh Users" actionClass="secondary" successMessage="Refreshed Users" />
      <Form onFormSubmit={setAccount} actionName="Refresh Account" actionClass="secondary" successMessage="Refreshed Account" />
      <Form actionClass="secondary fl-r" actionName="Delete" onFormSubmit={deleteAccount} />
    </Sidebar>
  );
};

AccountEditSidebar.propTypes = {
  data: PropTypes.shape( AccountSpec ).isRequired,
  editMode: PropTypes.bool.isRequired,
  autocomplete: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  upsertUsers: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

export default AccountEditSidebar;
