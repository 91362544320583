const blank = {
  templateName: "Blank",
  id: 0,
  cookie: {
    title: "",
    description: "",
    tag: "",
    cookieNames: [],
    headerNodes: "",
    bodyNodes: "",
  },
};

const analytics = {
  templateName: "Analytics",
  id: 3,
  cookie: {
    cookieNames: [
      "_ga",
      "_gid",
      "_gat",
      "__utma",
      "__utmt",
      "__utmb",
      "__utmc",
      "__utmz",
      "__utmv",
      "roarpaidsearchuser",
      "_gcl_au",
      "_gcl_aw",
    ],
    _id: "5dc024100621c6061ea702b8",
    title: "Analytics",
    description: "These cookies allow us to understand how people use our website and where they came from, so that we can measure and improve the performance of our website. They help us see which pages are the most and least popular, and how visitors use our website.\nWhen we use the information from these cookies it is grouped together and therefore anonymous. If you do not allow these cookies we will not know when you have visited our website and will not be able to monitor its performance.",
    tag: "analy",
    headerNodes: "<!-- examples: tag manger, analytics, roar lead tracking -->",
    bodyNodes: "<!-- examples: tag manger, analytics, roar lead tracking -->",
  },
};

const consentInformation = {
  templateName: "Consent Information",
  id: 4,
  cookie: {
    cookieNames: [],
    displayType: "notoggle",
    _id: "5e172d7b624dad001162e81e",
    title: "Consent Information",
    description: "In addition to what is specified in this document, the User can manage preferences for Cookies directly from within their own browser and prevent – for example – third parties from installing Cookies.\n\nThrough browser preferences, it is also possible to delete Cookies installed in the past, including the Cookies that may have saved the initial consent for the installation of Cookies by this website.\n\nUsers can, for example, find information about how to manage Cookies in the most commonly used browsers at the following addresses: \n<a target=\"_blank\" href=\"https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies\">Google Chrome</a>, <a target=\"_blank\" href=\"https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences\">Mozilla Firefox</a>, <a target=\"_blank\" href=\"https://support.apple.com/en-gb/guide/safari/sfri11471/mac\">Apple Safari</a> and <a target=\"_blank\" href=\"https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies\">Microsoft Internet Explorer</a>.\n\nTo find out more about cookies, visit <a target=\"_blank\" href=\"www.aboutcookies.org\">www.aboutcookies.org</a>. For more information about our use of cookies, please refer to our <a href=\"/pages/privacy-policy\">Privacy Policy</a> and our <a href=\"/pages/cookies\">Cookie Policy</a>.\n",
    tag: "info1",
    headerNodes: "",
    bodyNodes: "",
  },
};

const essential = {
  templateName: "Essential",
  id: 5,
  cookie: {
    cookieNames: [],
    displayType: "alwayson",
    _id: "5e172e04624dad001162e822",
    title: "Essential",
    description: "Essential cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.",
    tag: "essen",
    headerNodes: "",
    bodyNodes: "",
  },
};

const functional = {
  templateName: "Functional",
  id: 6,
  cookie: {
    cookieNames: [],
    displayType: "toggle",
    _id: "5e172e1a624dad001162e826",
    title: "Functional",
    description: "These cookies enable useful 3rd-party features on the site, such as the ability to see Feefo reviews. These features are non-essential, but will improve your experience on the site when enabled. If you disable these cookies, some features will no longer work.",
    tag: "funct",
    headerNodes: "<!-- examples: feefo, youtube/vimeo triggers, google maps api etc -->",
    bodyNodes: "<!-- examples: feefo, youtube/vimeo triggers, google maps api etc -->",
  },
};

const marketing = {
  templateName: "Marketing",
  id: 7,
  cookie: {
    cookieNames: [
      "c_user",
      "datr",
      "fr",
      "pl",
      "sb",
      "wd",
      "xs",
      "IDE",
      "AID",
    ],
    displayType: "toggle",
    _id: "5ddfca42c626a0001183bf88",
    title: "Marketing",
    description: "These cookies are used to serve advertising to site visitors on non-Google websites and on Facebook, to make them more relevant and to track the efficiency of our advertising campaigns.",
    tag: "mrkt1",
    headerNodes: "<!-- examples: facebook pixel, ad-targeting -->",
    bodyNodes: "<!-- examples: facebook pixel, ad-targeting -->",
  },
};

export default [
  blank,
  consentInformation,
  essential,
  functional,
  analytics,
  marketing,
];
