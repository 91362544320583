import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "roar-component-library";
import { Link } from "react-router-dom";

import AccountSpec from "../helpers/AccountSpec";

const AccountAdminView = ( props ) => {
  const {
    data, createContainer, setAccount, toggleEditMode,
  } = props;
  return (
    <div>
      <h1>
        { data.name }
        <button type="button" onClick={setAccount} aria-label="Refresh account" title="Refresh account" className="account__edit">
          <i className="fas fa-sync-alt" />
        </button>
        <button type="button" onClick={() => { toggleEditMode( true ); }} aria-label="Edit account" title="Edit account" className="account__edit">
          <i className="fas fa-edit" />
        </button>
      </h1>
      <h2>My Popups</h2>
      {data.containers.length > 0 ? (
        <>
          <div className="microtable" style={{ maxWidth: "450px" }}>
            {data.containers.map( ( container ) => (
              <div key={container._id} className="microtable__row">
                <strong className="microtable__row--mr">
                  {container.name}
                </strong>
                <Link label="View popup" className="primary" to={`/account/${ data._id }/${ container._id }`}>View</Link>
              </div>
            ) ) }
          </div>
          <h3>Or</h3>
        </>
      ) : "Create a Popup below to get started." }
      <h2>Create New Popup</h2>
      <Form onFormSubmit={( body ) => createContainer( body )} actionName="Create" actionClass="primary">
        <Field type="text" label="Popup name" name="name" minLen={1} maxLen={50} trim />
      </Form>
    </div>
  );
};

AccountAdminView.propTypes = {
  createContainer: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  data: PropTypes.shape( AccountSpec ).isRequired,
};

export default AccountAdminView;
