/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import currentUser from "../../models/currentUser";

function Nav( props: {accounts: any, location: {pathname: string }, updateNav: Function, user: currentUser } ) {
  const {
    accounts,
    location,
    updateNav,
    user,
  } = props;

  useEffect( () => {
    ReactGA.pageview( location.pathname );
  }, [ location ] );

  return (
    <nav className="nav max-width">
      <div className="nav__links">
        {accountLinks( accounts, location.pathname )}
        <div className="text-right">
          {refreshButton( accounts, updateNav )}
          {newLink( user ) }
        </div>
      </div>
    </nav>
  );
}

function newLink( user: any ) {
  if ( user && user.roles && ( user.roles.indexOf( "admin" ) >= 0 || user.roles.indexOf( "superuser" ) >= 0 ) ) {
    return (
      <Link to="/new" data-testid="nav-create" className="nav__link__button inline-block">
        <div className="nav__link">
          <i className="fas fa-plus" />
        </div>
      </Link>
    );
  }
  return false;
}

function refreshButton( accounts: any, updateNav: Function ) {
  if ( accounts.loading ) {
    return <div data-testid="nav-loading" className="defaultbtn nav__link__button inline-block"><span className="spinner spinner--mini" /></div>;
  }
  return (
    <button type="button" data-testid="nav-refresh" className="defaultbtn nav__link__button inline-block" onClick={() => { updateNav(); }}>
      <div className="nav__link">
        <i className="fas fa-sync-alt" />
      </div>
    </button>
  );
}

function accountLinks( accounts: any, path: string ) {
  if ( accounts.error ) {
    return <div className="nav__error">{ accounts.error }</div>;
  }
  return accounts.data.map( ( account: any, i: Number ) => (
    <Link key={`acclink-${ i }`} to={`/account/${ account._id }`} className={`nav__account ${ path.indexOf( account._id ) > -1 ? "nav__account--active" : "" }`}>{account.name}</Link>
  ) );
}

Nav.propTypes = {
  accounts: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  updateNav: PropTypes.func.isRequired,
  user: PropTypes.object,
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default withRouter( ( props : any ) => <Nav {...props} /> );
