import React, { Component } from "react";
import PropTypes from "prop-types";
import Ssomg from "ssomg-react";
import { Link } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar.tsx";
import Deploy from "../components/deploy";

export default class Container extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      archivedContainers: [],
      deployingContainer: null,
      container: {},
      loading: true,
      error: false,
    };
    this.setHistory = this.setHistory.bind( this );
    this.updateContainer = this.updateContainer.bind( this );
  }

  componentDidMount() {
    this.setHistory();
  }

  setHistory() {
    const { match, Server } = this.props;
    const { accountId, containerId } = match.params;
    this.setState( { loading: true } );
    Server.get( `/api/accounts/${ accountId }/containers/${ containerId }/history`, { ga: { label: "/api/accounts/:accountId/containers/:containerId/history" } } )
      .then( ( res ) => {
        let { archivedContainers } = res.data.data;
        const { container } = res.data.data;
        if ( archivedContainers.length > 0 ) {
          archivedContainers = archivedContainers.sort( ( a, b ) => new Date( b.publishedOn ) - new Date( a.publishedOn ) );
        }
        this.setState( {
          archivedContainers, container, loading: false, error: false,
        } );
        document.title = `${ container.name } History - Cookie Manager`;
      } )
      .catch( ( err ) => {
        this.setState( {
          error: err.message || err || "An error occurred. Please wait a moment and try again.",
          loading: false,
        } );
      } );
  }

  updateContainer( update ) {
    const { match, history, Server } = this.props;
    const { accountId, containerId } = match.params;
    const {
      name,
      title,
      introduction,
      cookies,
      customStyles,
      popupVersion,
      closedPopupText,
      cookiePolicyUrl,
    } = update;
    const body = {
      name,
      title,
      introduction,
      cookies,
      customStyles,
      popupVersion,
      closedPopupText,
      cookiePolicyUrl,
    };
    return Server.post( `/api/accounts/${ accountId }/containers/${ containerId }`, body, { ga: { label: "/api/accounts/:accountId/containers/:containerId" } } )
      .then( ( ) => {
        history.push( `/account/${ accountId }/${ containerId }` );
      } )
      .catch( ( err ) => {
        this.setState( {
          error: err.message || err || "An error occurred. Please wait a moment and try again.",
          loading: false,
        } );
      } );
  }

  headerMarkup() {
    const { container } = this.state;
    const { match } = this.props;
    const { accountId, containerId } = match.params;
    return (
      <h1>
        { container ? `${ container.name } History` : "" }
        <button type="button" onClick={this.setHistory} aria-label="Refresh history" title="Refresh history" className="account__edit">
          <i className="fas fa-sync-alt" />
        </button>
        <Link to={`/account/${ accountId }/${ containerId }`} title="Back to popup" aria-label="Back to popup" className="account__edit">
          <i className="fas fa-arrow-left" />
        </Link>
      </h1>
    );
  }

  render() {
    const {
      loading, archivedContainers, error, deployingContainer,
    } = this.state;
    if ( loading ) {
      return (
        <div>
          <div className="text-center"><span className="spinner spinner--blue" /></div>
        </div>
      );
    }
    if ( error ) {
      return (
        <div>
          {this.headerMarkup()}
          <h2 className="text-center">{error}</h2>
        </div>
      );
    }

    return (
      <div>
        {this.headerMarkup()}
        {archivedContainers.length === 0 ? <div>No history!</div> : (
          <div className="microtable">
            {archivedContainers.map( ( archivedContainer, i ) => (
              <div key={archivedContainer.version} className="microtable__row">
                <strong>
                  {`Version ${ archivedContainer.version }`}
                </strong>
                <details className="microtable__row--mr">
                  <summary className="secondary">View Source</summary>
                  <code>
                    {JSON.stringify( archivedContainer, null, 2 )}
                  </code>
                </details>
                { archivedContainer.totalResponses ? (
                  <span>
                    {`Total responses: ${ archivedContainer.totalResponses }, Acc all: ${ archivedContainer.acceptAllCount }, Rej all: ${ archivedContainer.rejectAllCount }`}
                  </span>
                ) : false }
                <button type="button" className="secondary" onClick={() => { this.updateContainer( archivedContainer ); }} title="Restore editor to this version">Restore</button>
                <button type="button" className="secondary" onClick={() => { this.setState( { deployingContainer: archivedContainer } ); }} title="Apply this version to another popup">Merge</button>
              </div>
            ) ) }
          </div>
        )}
        <Sidebar open={!!deployingContainer} back={() => { this.setState( { deployingContainer: null } ); }} style={{ width: "500px" }}>
          { deployingContainer ? ( <Deploy container={deployingContainer} goBack={() => { this.setState( { deployingContainer: null } ); }} /> ) : false}
        </Sidebar>
      </div>
    );
  }
}

Container.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  Server: PropTypes.instanceOf( Ssomg.Network ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

Container.defaultProps = {
  user: {},
};
