/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import {
  Route, Link, BrowserRouter, Switch,
} from "react-router-dom";
import ReactGA from "react-ga";

import Ssomg from "ssomg-react";
import Nav from "./components/nav/Nav.tsx";
import NewAccount from "./pages/newAccount";
import Account from "./pages/Account/Account";
import Container from "./pages/Container";
import History from "./pages/History";

const Server = new Ssomg.Network();
class App extends Component {
  constructor( props ) {
    super( props );
    Ssomg.onload();
    if ( process.env.NODE_ENV !== "production" ) {
      ReactGA.initialize( "UA-100513233-4", { titleCase: false } );
    } else {
      ReactGA.initialize( "UA-100513233-5", { titleCase: false } );
    }
    ReactGA.ga( "set", "checkProtocolTask", null );

    this.state = {
      accounts: {
        data: [],
        loading: true,
        error: false,
      },
      user: undefined,
      booted: false,
    };
  }

  componentDidMount() {
    this.loadAllData();
    window.server = Server;
  }

  getAccounts() {
    const { accounts } = this.state;
    const accountsUpdate = accounts;
    if ( !accounts.loading ) {
      accountsUpdate.loading = true;
      this.setState( { accounts: accountsUpdate } );
    }
    return Server.get( "/api/accounts" ).then( ( res ) => {
      this.setState( { accounts: { data: Array.from( res.data.data ), loading: false, error: false } } );
    } ).catch( ( err ) => {
      this.setState( { accounts: { data: [], loading: false, error: err } } );
      throw err;
    } );
  }

  loadAllData( isTokenRefreshCallback = false ) {
    const { accounts, booted } = this.state;
    const accountsUpdate = accounts;
    console.log( booted, isTokenRefreshCallback );
    if ( booted && isTokenRefreshCallback ) {
      return Ssomg.getObject().then( ( user ) => {
        this.setState( { user } );
      } );
    }
    if ( !accounts.loading ) {
      accountsUpdate.loading = true;
      this.setState( { accounts: accountsUpdate } );
    }
    return Ssomg.getObject()
      .then( ( user ) => Server.get( "/api/accounts" ).then( ( res ) => {
        this.setState( { accounts: { data: Array.from( res.data.data ), loading: false, error: false }, user, booted: true } );
      } ) )
      .catch( ( err ) => {
        console.log( JSON.stringify( err ) );
        let errMessage;
        if ( err.response && err.response.data && err.response.data.error ) {
          errMessage = err.response.data.error;
        } else if ( err.message ) {
          errMessage = err.message;
        } else if ( typeof err !== "string" ) {
          if ( isTokenRefreshCallback ) {
            errMessage = "An authentication error occurred. Please clear your cookies or reload the browser.";
          } // else - otherwise authentication still in progress to keep loading spinner
        } else {
          errMessage = err;
        }

        if ( errMessage ) {
          this.setState( { accounts: { data: [], loading: false, error: errMessage } } );
        }
      } );
  }

  render() {
    const { accounts, user } = this.state;
    let isAdmin = false;
    if ( user && user.roles ) {
      if ( user.roles.indexOf( "admin" ) >= 0 || user.roles.indexOf( "superuser" ) >= 0 ) { isAdmin = true; }
    }
    return (
      <BrowserRouter>
        <header className="header">
          <div className="header__inner">
            <div className="header__left">
              <h4>Cookie Manager</h4>
            </div>
            <div className="header__right">
              <img className="roar-branding" alt="Roar Digital" src="/roar-logo-dark.png" />
              <Link to="/auth/logout" className="logout">
                <i className="fas fa-sign-out-alt" />
              </Link>
            </div>
          </div>
        </header>
        <div className="app" key={( user && user._id ) || "nouserset"}>
          <Nav accounts={accounts} user={user} updateNav={() => this.loadAllData()} />
          <main>
            <Switch>
              <Route
                exact
                path="/"
                render={( props ) => {
                  document.title = "Cookie Manager";
                  if ( accounts.loading ) return false;
                  if ( accounts.data.length === 0 ) {
                    return (
                      <div>
                        <h2>
                          {user ? `Hi, ${ user.first_name }` : "Welcome"}
                        </h2>
                        <p>
                          {isAdmin ? "Click the plus on the left to get started" : "You're not currently assigned to any accounts. Please request access from an account administrator."}
                        </p>
                      </div>
                    );
                  }
                  return (
                    <div>
                      <h2>
                        {user ? `Hi, ${ user.first_name }` : "Welcome"}
                      </h2>
                      <p>Click an account on the left to get started.</p>
                    </div>
                  );
                }}
              />
              <Route
                exact
                path="/auth/:action"
                render={( props ) => <Ssomg.component {...props} callback={() => this.loadAllData( true )} />}
              />
              <Route
                exact
                path="/new"
                render={( props ) => {
                  document.title = "Create account - Cookie Manager";
                  return <NewAccount {...props} Server={Server} updateNav={() => this.loadAllData()} />;
                }}
              />
              <Route
                path="/account/:accountId/:containerId/history"
                render={( props ) => {
                  let account;
                  accounts.data.forEach( ( acc ) => {
                    if ( acc._id === props.match.params.accountId ) {
                      account = acc;
                    }
                  } );
                  if ( !account ) {
                    document.title = "404 - Cookie Manager";
                    return <h1>404</h1>;
                  }
                  return (
                    <History
                      {...props}
                      account={account}
                      Server={Server}
                      user={user}
                      updateNav={() => this.loadAllData()}
                    />
                  );
                }}
              />
              <Route
                path="/account/:accountId/:containerId"
                render={( props ) => {
                  let account;
                  accounts.data.forEach( ( acc ) => {
                    if ( acc._id === props.match.params.accountId ) {
                      account = acc;
                    }
                  } );
                  if ( !account ) {
                    document.title = "404 - Cookie Manager";
                    return <h1>404</h1>;
                  }
                  return (
                    <Container
                      {...props}
                      account={account}
                      Server={Server}
                      user={user}
                      updateNav={() => this.loadAllData()}
                    />
                  );
                }}
              />
              <Route
                path="/account/:accountId"
                render={( props ) => {
                  let account;
                  accounts.data.forEach( ( acc ) => {
                    if ( acc._id === props.match.params.accountId ) {
                      account = acc;
                    }
                  } );
                  if ( !account ) {
                    document.title = "404 - Cookie Manager";
                    return <h1>404</h1>;
                  }
                  document.title = `${ account.name } - Accounts - Cookie Manager`;
                  return (
                    <Account
                      {...props}
                      initialAccount={account}
                      Server={Server}
                      user={user}
                      updateNav={() => this.loadAllData()}
                    />
                  );
                }}
              />
            </Switch>
          </main>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
