import React from "react";
import { Form, Field } from "roar-component-library";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";
import Ssomg from "ssomg-react";
import hooks from "../hooks";

async function saveContainer( update, accountId, containerId ) {
  const Server = new Ssomg.Network();
  const current = await Server.get( `/api/accounts/${ accountId }/containers/${ containerId }` );
  const { name } = current.data.data;
  const {
    title,
    introduction,
    cookies,
    customStyles,
    popupVersion,
    closedPopupText,
    cookiePolicyUrl,
  } = update;
  const body = {
    name,
    title,
    introduction,
    cookies,
    customStyles,
    popupVersion,
    closedPopupText,
    cookiePolicyUrl,
  };
  return Server.post( `/api/accounts/${ accountId }/containers/${ containerId }`, body );
}

function Deploy( { container, goBack } ) {
  const [ error, account ] = hooks.useAccount();
  const match = useRouteMatch();
  const { accountId, containerId } = match.params;
  const options = [ {
    label: "Select One",
    value: "",
  } ];
  if ( account ) {
    account.containers.forEach( ( el ) => {
      if ( containerId !== el.id ) {
        options.push( {
          label: el.name,
          value: el.id,
        } );
      }
    } );
  }
  function deploy( targetId ) {
    return saveContainer( container, accountId, targetId );
  }

  return (
    <div>
      <h2>
        Apply to Another Popup
        <button type="button" onClick={goBack} aria-label="Back" title="Back" className="account__edit">
          <i className="fas fa-arrow-left" />
        </button>
      </h2>
      <p>This will save this version of the popup to another another popup you own. This could be used to apply your staging popup to production for example. You will still need to deploy the popup after applying these settings.</p>
      <Form onFormSubmit={( formData ) => deploy( formData.target )} actionName="Apply" actionClass="primary" successMessage="Applied">
        <Field name="target" label="Target Popup" type="select" options={options} required />
      </Form>
    </div>
  );
}

Deploy.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  container: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default Deploy;
