import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "roar-component-library";
import Sidebar from "../components/sidebar/Sidebar.tsx";
import CookieForm from "../components/CookieForm";
import templates from "../cookieTemplates";

export default class Cookies extends Component {
  constructor( props ) {
    super( props );
    this.updateCookie = this.updateCookie.bind( this );
    this.pushCookie = this.pushCookie.bind( this );
    this.removeCookie = this.removeCookie.bind( this );
    this.goToDefault = this.goToDefault.bind( this );
    this.goBack = this.goBack.bind( this );
    this.swapCookiePositions = this.swapCookiePositions.bind( this );
    this.state = {
      page: "editcookies",
      editIndex: 0,
      cookieTemplate: templates[ 0 ],
    };
  }

  updateCookie( i ) {
    const self = this;
    if ( !Number.isInteger( i ) ) throw new Error( "Config mistake - no index for updateCookie set." );
    return ( cookie ) => {
      const { cookies, setCookies } = self.props;
      const cookiesUpdate = cookies.slice();
      cookiesUpdate[ i ] = cookie;
      setCookies( cookiesUpdate );
      this.goToDefault();
    };
  }

  swapCookiePositions( from, to ) {
    const self = this;
    if ( !Number.isInteger( from ) ) throw new Error( "Config mistake - no from for shiftCookie set." );
    if ( !Number.isInteger( to ) ) throw new Error( "Config mistake - no to for shiftCookie set." );
    if ( from === to ) throw new Error( "Config mistake - from can't equal to for shiftCookie." );
    return ( ) => {
      const { cookies, setCookies } = self.props;
      const cookiesUpdate = cookies.slice();
      const targetElement = cookiesUpdate.slice( to, to + 1 )[ 0 ];
      cookiesUpdate[ to ] = cookiesUpdate.slice( from, from + 1 )[ 0 ];
      cookiesUpdate[ from ] = targetElement;
      setCookies( cookiesUpdate );
    };
  }

  pushCookie( cookie ) {
    const { cookies, setCookies } = this.props;
    const cookiesUpdate = cookies.slice();
    cookiesUpdate.push( cookie );
    setCookies( cookiesUpdate );
    this.goToDefault();
  }

  goToDefault() {
    this.setState( { page: "editcookies", cookieTemplate: templates[ 0 ] } );
  }

  removeCookie( i ) {
    const self = this;
    if ( !Number.isInteger( i ) ) throw new Error( "Config mistake - no index for removeCookie set." );
    return () => {
      if ( !window.confirm( "Are you sure you want to delete this cookie? This cannot be undone." ) ) return;
      const { cookies, setCookies } = self.props;
      const cookiesUpdate = cookies.slice();
      cookiesUpdate.splice( i, 1 );
      setCookies( cookiesUpdate );
      this.goToDefault();
    };
  }

  goBack() {
    const { back } = this.props;
    this.goToDefault();
    back();
  }

  templateSelector() {
    const { cookieTemplate } = this.state;
    if ( !Array.isArray( templates ) ) return false;
    function templateSelectorClassName( template ) {
      if ( cookieTemplate && template.id === cookieTemplate.id ) return "primary";
      return "secondary";
    }
    return (
      <div className="microtable__row microtable__row--wrap" style={{ paddingLeft: "0px" }}>
        { templates.map( ( template ) => (
          <button type="button" key={template.id} className={templateSelectorClassName( template )} onClick={() => { this.setState( { cookieTemplate: template } ); }}>{template.templateName}</button>
        ) )}
      </div>
    );
  }

  render() {
    const { cookies, editCookiesMode } = this.props;
    const { page, editIndex, cookieTemplate } = this.state;
    const openEditCookiesSidebar = editCookiesMode && page === "editcookies";
    const openAddCookieSidebar = editCookiesMode && page === "addcookie";
    const openEditCookieSidebar = editCookiesMode && page === "editcookie";
    const sidebarStyles = { width: "500px" };
    let cookieToEdit;
    if ( editIndex >= 0 && editIndex < cookies.length ) {
      cookieToEdit = cookies[ editIndex ];
    }
    return (
      <>
        <Sidebar open={openEditCookiesSidebar} back={this.goBack} style={sidebarStyles}>
          <h2>
            Edit Cookies
            <button type="button" onClick={this.goBack} aria-label="Back" title="Back" className="account__edit">
              <i className="fas fa-arrow-left" />
            </button>
          </h2>
          <p>
            Be sure to save your changes on the Edit Container screen when you&apos;re done!
          </p>
          {cookies.length === 0 ? <div>No cookies!</div> : (
            <div className="microtable">
              {cookies.map( ( cookie, i ) => (
                <div key={cookie.tag} className="microtable__row">
                  <strong className="microtable__row--mr">
                    {cookie.title}
                  </strong>
                  <button type="button" className={`tertiary ${ i === 0 ? "tertiary--disabled" : "" }`} onClick={i > 0 ? this.swapCookiePositions( i, i - 1 ) : undefined}>
                    <i className="fas fa-chevron-up" />
                  </button>
                  <button type="button" className={`tertiary ${ i === cookies.length - 1 ? "tertiary--disabled" : "" }`} onClick={i < cookies.length - 1 ? this.swapCookiePositions( i, i + 1 ) : undefined}>
                    <i className="fas fa-chevron-down" />
                  </button>
                  <button type="button" className="secondary" onClick={() => { this.setState( { page: "editcookie", editIndex: i } ); }}>Edit</button>
                </div>
              ) ) }
            </div>
          )}
          <button type="button" className="secondary" onClick={() => { this.setState( { page: "addcookie" } ); }}>Create new cookie</button>
          <button type="button" className="primary fl-r" onClick={this.goBack}>Done editing</button>
        </Sidebar>
        <Sidebar open={openAddCookieSidebar} back={this.goToDefault} style={sidebarStyles}>
          <h2>
            Create Cookie
            <button type="button" onClick={this.goToDefault} aria-label="Back" title="Back" className="account__edit">
              <i className="fas fa-arrow-left" />
            </button>
          </h2>
          {this.templateSelector()}
          <CookieForm key={cookieTemplate ? `${ cookieTemplate.templateName }-${ cookies.length }` : "none"} cookie={cookieTemplate ? cookieTemplate.cookie : undefined} onFormSubmit={this.pushCookie} actionName="Done" cookies={cookies} />
        </Sidebar>
        <Sidebar open={openEditCookieSidebar} back={this.goToDefault} style={sidebarStyles}>
          {cookieToEdit ? (
            <>
              <h2>
                {`Edit ${ cookieToEdit.title }`}
                <button type="button" onClick={this.goToDefault} aria-label="Back" title="Back" className="account__edit">
                  <i className="fas fa-arrow-left" />
                </button>
              </h2>

              <CookieForm onFormSubmit={this.updateCookie( editIndex )} cookie={cookieToEdit} index={editIndex} actionName="Done" cookies={cookies} />
              <Form onFormSubmit={this.removeCookie( editIndex )} actionName="Delete" actionClass="secondary fl-r" />
            </>
          ) : false }
        </Sidebar>
      </>
    );
  }
}

Cookies.defaultProps = {
  editCookiesMode: false,
};

Cookies.propTypes = {
  cookies: PropTypes.arrayOf( PropTypes.shape( {
    title: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    cookieNames: PropTypes.array,
    headerNodes: PropTypes.string,
    bodyNodes: PropTypes.string,
  } ) ).isRequired,
  back: PropTypes.func.isRequired,
  editCookiesMode: PropTypes.bool,
  setCookies: PropTypes.func.isRequired,
};
