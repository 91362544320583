import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "roar-component-library";

const displayTypes = [
  {
    label: "Toggle (default)",
    value: "toggle",
  },
  {
    label: "Always On",
    value: "alwayson",
  },
  {
    label: "Just text",
    value: "notoggle",
  },
];

export default function CookieForm( props ) {
  const {
    cookie,
    index,
    onFormSubmit,
    actionName,
    cookies,
  } = props;
  const { cookieNames } = cookie;
  function handleFormSubmit( formData ) {
    if ( formData.cookieNames && typeof formData.cookieNames === "string" && formData.cookieNames.trim().length > 0 ) {
      formData.cookieNames = formData.cookieNames.split( "," ).map( ( name ) => name.trim() );
    } else {
      formData.cookieNames = [];
    }
    onFormSubmit( formData );
  }

  let currentTags = [];
  let currentTag;
  if ( cookies ) {
    currentTags = cookies.map( ( c ) => c.tag );
    if ( Number.isInteger( index ) ) {
      currentTag = currentTags.splice( index, 1 );
    }
  }

  return (
    <Form key={currentTag} onFormSubmit={( formData ) => { handleFormSubmit( formData ); }} actionName={actionName} actionClass="primary">
      <h3>Cookie Information</h3>
      <Field name="title" type="text" parentClass="block" default={cookie.title} label="Cookie title" />
      <Field name="description" type="textarea" rows={5} parentClass="block" default={cookie.description} label="Cookie description" />
      <Field name="displayType" type="select" options={displayTypes} default={cookie.displayType} label="Display type" />
      <br />
      <h3>Cookie Configuration</h3>
      <Field
        name="tag"
        type="text"
        parentClass="block"
        default={cookie.tag}
        minLen={5}
        maxLen={5}
        validate={( field, form ) => {
          const tags = currentTags.filter( ( tag ) => tag === field );
          if ( tags.length > 0 ) return "Duplicate tag!";
        }}
        trim
        label="Tag (5 character unique identifier)"
      />
      <Field name="cookieNames" type="text" parentClass="block" default={cookieNames.join( "," )} label="Cookie names ( comma-separated )" />
      <Field name="headerNodes" type="textarea" parentClass="block" default={cookie.headerNodes} label="Head code" />
      <Field name="bodyNodes" type="textarea" parentClass="block" default={cookie.bodyNodes} label="Body code" />
    </Form>
  );
}

CookieForm.defaultProps = {
  cookie: {
    title: "",
    description: "",
    tag: "",
    cookieNames: [],
    headerNodes: "",
    bodyNodes: "",
  },
  actionName: "Submit",
  index: undefined,
};

CookieForm.propTypes = {
  cookie: PropTypes.shape( {
    title: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    cookieNames: PropTypes.array,
    headerNodes: PropTypes.string,
    bodyNodes: PropTypes.string,
    displayType: PropTypes.string,
  } ),
  onFormSubmit: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  cookies: PropTypes.arrayOf( PropTypes.shape( {
    title: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    cookieNames: PropTypes.array,
    headerNodes: PropTypes.string,
    bodyNodes: PropTypes.string,
    displayType: PropTypes.string,
  } ) ).isRequired,
  index: PropTypes.number,
};
