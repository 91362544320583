import { useState, useEffect } from "react";
import Ssomg from "ssomg-react";
import { useRouteMatch } from "react-router-dom";

export default function useAccount( accountIdRaw ) {
  let accountId = accountIdRaw;
  const Server = new Ssomg.Network();
  const [ account, setAccount ] = useState( null );
  const [ error, setError ] = useState( null );
  const match = useRouteMatch();
  if ( !accountIdRaw ) {
    accountId = match.params.accountId;
  }
  useEffect( () => {
    Server.get( `/api/accounts/${ accountId }` )
      .then( ( res ) => {
        setAccount( res.data.data );
      } ).catch( ( err ) => {
        setError( err.message || err || "An error occurred. Please wait a moment and try again." );
      } );
  }, [ accountId ] );
  return [ error, account ];
}
