import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AccountSpec from "../helpers/AccountSpec";

const AccountUserView = ( props ) => {
  const { data, setAccount } = props;
  return (
    <div>
      <h1>
        { data.name }
        <button type="button" onClick={setAccount} aria-label="Refresh account" title="Refresh account" className="account__edit">
          <i className="fas fa-sync-alt" />
        </button>
      </h1>
      <h2>My Popups</h2>
      {data.containers.length > 0 ? (
        <>
          <div className="microtable" style={{ maxWidth: "450px" }}>
            {data.containers.map( ( container ) => (
              <div key={container._id} className="microtable__row">
                <strong className="microtable__row--mr">
                  {container.name}
                </strong>
                <Link label="View popup" className="primary" to={`/account/${ data._id }/${ container._id }`}>View</Link>
              </div>
            ) ) }
          </div>
        </>
      ) : "No popups available. Please speak to an account admin to create one." }
    </div>
  );
};

AccountUserView.propTypes = {
  setAccount: PropTypes.func.isRequired,
  data: PropTypes.shape( AccountSpec ).isRequired,
};

export default AccountUserView;
